// v2 version of events. Migrate event to events when complete

import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { newStripeOrder, setStripeSessionID } from '../actions/stripe'
import { STRIPE_CREATE_SESSION_URL } from '../constants'
import { post } from '../utils/saga-fetch'

function* startNewStripeOrder(action) {
  const { eventID } = action.payload
  const { sessionID } = yield post(STRIPE_CREATE_SESSION_URL, { eventID })

  yield put(setStripeSessionID(sessionID))
}

function* watchNewStripeOrder() {
  yield takeLatest(`${newStripeOrder}`, startNewStripeOrder)
}

export default function* stripeSaga() {
  yield all([fork(watchNewStripeOrder)])
}
