/**
 * @file Reducer states for all the different matches
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import { updateHotlineMatches, updateMatchDetails } from '../actions/match'

// see https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
// for the pattern of normalized entries
const defaultState = {
  details: {},
  hotlineCallMatches: {
    ids: [], // array of loaded IDs. in order (if the filter changes, this should be purged)
    loading: false,
    canLoadMore: true,
  },
}

export default handleActions(
  {
    [`${updateMatchDetails}`]: (state, action) => ({
      ...state,
      details: {
        ...state.details,
        ...action.payload,
      },
    }),
    [`${updateHotlineMatches}`]: (state, action) => ({
      ...state,
      hotlineCallMatches: {
        ...state.hotlineCallMatches,
        ...action.payload,
      },
    }),
  },
  defaultState
)
