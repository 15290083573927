/**
 * @file Actions for the matches
 * @author Alwyn Tan
 */

import { createAction } from 'redux-actions'

export const loadHotlineMatches = createAction('LOAD_HOTLINE_MATCHES')
export const updateMatchDetails = createAction('UPDATE_MATCH_DETAILS')
export const updateHotlineMatches = createAction('UPDATE_HOTLINE_MATCHES')
export const rateMatch = createAction('RATE_MATCH')
