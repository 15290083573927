/**
 * @file Manages all sagas
 * @author Alwyn Tan
 */

import { all, fork } from 'redux-saga/effects'
import chatSaga from './chat'
import authSaga from './auth'
import callsSaga from './calls'
import eventsSaga from './events'
import matchSaga from './match'
import notificationsSaga from './notifications'
import podSaga from './pod'
import userSaga from './user'
import stripeSaga from './stripe'
import verificationSaga from './verification'

export default function* rootSaga() {
  yield all([
    fork(chatSaga),
    fork(authSaga),
    fork(callsSaga),
    fork(eventsSaga),
    fork(matchSaga),
    fork(notificationsSaga),
    fork(podSaga),
    fork(userSaga),
    fork(stripeSaga),
    fork(verificationSaga),
  ])
}
