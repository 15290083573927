/**
 * @file Reducers for all user related updates (except user object)
 * @author Alwyn Tan
 */

// NOTE: USER OBJECT RESIDES IN AUTH

import { clamp } from 'lodash'
import { handleActions } from 'redux-actions'
import {
  setUserInfo,
  updateOnboardingUserStore,
  setLoadingUser,
} from '../actions/user'

const ONBOARDING_USER_SEGMENTS = 2

const defaultState = {
  loading: false,
  info: {}, // stores all loaded users' info by id -> {user}
  onboardingUserStore: {
    progress: 0,
    segments: ONBOARDING_USER_SEGMENTS,
    formData: {},
  },
}

export default handleActions(
  {
    [`${setUserInfo}`]: (state, action) => ({
      ...state,
      info: action.payload,
    }),
    [`${updateOnboardingUserStore}`]: (state, action) => ({
      ...state,
      onboardingUserStore: {
        ...state.onboardingUserStore,
        progress: clamp(
          action.payload.progress,
          0,
          ONBOARDING_USER_SEGMENTS - 1
        ),
        formData: {
          ...state.onboardingUserStore.formData,
          ...action.payload.data,
        },
      },
    }),
    [`${setLoadingUser}`]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
  },
  defaultState
)
