/**
 * @file Wraps the entire gastby with this (Similar to app.js in tradiational react apps)
 * @author Alwyn Tan
 */

import React from 'react'
import { Provider, useSelector } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from 'styled-components'
import rootSaga from './src/sagas'
import sagaMiddleware from './sagaMiddleware'
import store from './store'
import { darkTheme, lightTheme } from './src/constants'
import { silentLogin } from './src/actions/auth'

// initializes required react components
const Initializer = ({ children }) => {
  const darkMode = useSelector(state => state.auth.user?.settings?.darkMode)
  const theme = darkMode ? darkTheme : lightTheme

  return (
    <ThemeProvider theme={theme}>
      {children}
      <Toaster
        reverseOrder
        toastOptions={{
          style: {
            borderRadius: 200,
            backgroundColor: theme.Secondary,
            color: theme.Text,
          },
        }}
      />
    </ThemeProvider>
  )
}

export default ({ element }) => {
  sagaMiddleware.run(rootSaga)
  store.dispatch(silentLogin())

  return (
    <Provider store={store}>
      <Initializer>{element}</Initializer>
    </Provider>
  )
}
