/**
 * @file Reducers for all authentication
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  setAccessToken,
  setAuthError,
  setAuthLoading,
  setCurrentUser,
  setAuthPhoneNumber,
  setAuthSuccessCallback,
  setVerified,
  setVerificationCode,
  setResetPasswordSuccess,
  setNextResendVerificationDate,
} from '../actions/auth'

const defaultState = {
  user: null,
  accessTokenLoaded: false,
  accessToken: null,
  authError: '',
  isLoading: false,
  userVerified: undefined,
  emailVerified: undefined,
  verified: false, // used for temporary authorizations
  verificationCode: '',
  nextResendVerificationDate: null,
  resetPasswordSuccess: false,
  authPhoneNumber: null,
  authSuccessCallback: null,
}

export default handleActions(
  {
    // if access token is set, to anything, that means it's been loaded
    [`${setAccessToken}`]: (state, action) => ({
      ...state,
      accessToken: action.payload?.accessToken,
      userVerified: action.payload?.userVerified,
      emailVerified: action.payload?.emailVerified,
      accessTokenLoaded: true,
      authError: '',
      isLoading: false,
    }),
    [`${setCurrentUser}`]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [`${setAuthError}`]: (state, action) => ({
      ...state,
      isLoading: false,
      authError: action.payload,
    }),
    [`${setAuthLoading}`]: (state, action) => ({
      ...state,
      isLoading: action.payload,
      authError: action.payload ? '' : state.authError,
    }),
    [`${setAuthPhoneNumber}`]: (state, action) => ({
      ...state,
      authPhoneNumber: action.payload,
    }),
    [`${setAuthSuccessCallback}`]: (state, action) => ({
      ...state,
      authSuccessCallback: action.payload,
    }),
    [`${setVerified}`]: (state, action) => ({
      ...state,
      verified: action.payload,
    }),
    [`${setVerificationCode}`]: (state, action) => ({
      ...state,
      verificationCode: action.payload,
    }),
    [`${setResetPasswordSuccess}`]: (state, action) => ({
      ...state,
      resetPasswordSuccess: action.payload,
    }),
    [`${setNextResendVerificationDate}`]: (state, action) => ({
      ...state,
      nextResendVerificationDate: action.payload,
    }),
  },
  defaultState
)
