/**
 * @file URL related helpers
 * @author Alwyn Tan
 */

/**
 * Helper to generate a URI string with the specified query params
 * @param {String} base Base URL
 * @param {Object} params Query Parameters
 */
export const makeURLWithQueryParams = (base, params) => {
  const query = Object.entries(params)
    .filter(entry => !!entry[1])
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

  return `${base}?${query}`
}

/**
 * Converts the URL query string to object
 * @param {String} queryString URL Query String
 */
export const convertQueryParamsToObject = queryString => {
  const pairsString =
    queryString[0] === '?' ? queryString.slice(1) : queryString
  const pairs = pairsString
    .split('&')
    .map(str => str.split('=').map(decodeURIComponent))
  return pairs.reduce(
    (acc, [key, value]) => (key ? { ...acc, [key]: value } : acc),
    {}
  )
}
