/**
 * @file Action creators for hotline calls
 * @author Alwyn Tan
 */

const { createAction } = require('redux-actions')

export const loadAvailableHotlines = createAction('LOAD_AVAILABLE_HOTLINES')
export const loadUpcomingHotlineCalls = createAction(
  'LOAD_UPCOMING_HOTLINE_CALLS'
)
export const getHotline = createAction('GET_HOTLINE')
export const updateAvailableHotlines = createAction('UPDATE_AVAILABLE_HOTLINES')
export const updateUpcomingHotlineCalls = createAction(
  'UPDATE_UPCOMING_HOTLINE_CALLS'
)
export const updateHotlineDetails = createAction('UPDATE_HOTLINE_DETAILS') // entire hotline detail object
export const updateCallDetails = createAction('UPDATE_CALL_DETAILS') // individual active calls within the hotline details
export const registerCall = createAction('REGISTER_CALL')
export const unregisterCall = createAction('UNREGISTER_CALL')
