/**
 * @file Reducers for stripe
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import { setStripeSessionID } from '../actions/stripe'

const defaultState = {
  sessionID: null,
}

export default handleActions(
  {
    [setStripeSessionID]: (state, action) => ({
      ...state,
      sessionID: action.payload,
    }),
  },
  defaultState
)
