/**
 * @file Action creators for user related events (update profile, etc)
 * @author Alwyn Tan
 */

const { createAction } = require('redux-actions')

export const setLoadingUser = createAction('SET_LOADING_USER')
export const updateUser = createAction('UPDATE_USER')
export const getUserInfo = createAction('GET_USER_INFO') // get public user data for a given user id
export const setUserInfo = createAction('SET_USER_INFO') // sets a public user data for a given user
export const updateOnboardingUserStore = createAction(
  'UPDATE_ONBOARDING_USER_STORE'
)
export const onboardUser = createAction('ONBOARD_USER')
