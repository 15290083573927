/**
 * @file Reducers for chat
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  chatSocketConnected,
  receiveChatMessage,
  setChatRoom,
  resolvePendingChatMessage,
  receivePendingChatMessage,
  receiveLoadedChatMessages,
  updateChatMessage,
  updateChatRoomDetails,
  updateDefaultChatRooms,
  updateEventChatRooms,
  receiveDefaultChatRoom,
  receiveEventChatRoom,
} from '../actions/chat'
import { CHAT_MESSAGES_PER_LOAD } from '../constants'

const defaultState = {
  socketConnected: false,
  roomDetails: {},
  pendingMessages: {},
  messages: {},

  defaultRooms: {
    // default chat rooms (people)
    ids: [],
    canLoadMore: true,
    loading: false,
  },
  eventRooms: {
    ids: [],
    canLoadMore: true,
    loading: false,
  },

  /*
  messages: {[roomID]: [Message arr]} // same as pendingMessages
  roomDetails: {[roomID]: details}
  */
}

// loaded rooms
// added rooms

export default handleActions(
  {
    [`${setChatRoom}`]: (state, action) => {
      const { roomDetails } = action.payload
      const roomID = roomDetails.id
      const updatedRoomDetails = state.roomDetails[roomID]
        ? {
            ...state.roomDetails[roomID],
            ...roomDetails,
          }
        : { ...roomDetails }

      return {
        ...state,
        roomDetails: {
          ...state.roomDetails,
          [roomID]: updatedRoomDetails,
        },
      }
    },
    [`${chatSocketConnected}`]: state => ({
      ...state,
      socketConnected: true,
    }),
    [`${receiveLoadedChatMessages}`]: (state, action) => {
      const { roomID, messages } = action.payload

      let updatedMessages = state.messages[roomID]
        ? [...state.messages[roomID]]
        : []
      updatedMessages = [...messages, ...updatedMessages]

      const updatedRoomDetails = { ...state.roomDetails[roomID] }

      if (messages.length < CHAT_MESSAGES_PER_LOAD)
        updatedRoomDetails.allMessagesLoaded = true

      return {
        ...state,
        messages: {
          ...state.messages,
          [roomID]: updatedMessages,
        },
        roomDetails: {
          ...state.roomDetails,
          [roomID]: updatedRoomDetails,
        },
      }
    },
    [`${receivePendingChatMessage}`]: (state, action) => {
      const { roomID, message } = action.payload

      const updatedMessages = state.pendingMessages[roomID]
        ? [...state.pendingMessages[roomID]]
        : []
      updatedMessages.push(message)

      return {
        ...state,
        pendingMessages: {
          ...state.pendingMessages,
          [roomID]: updatedMessages,
        },
      }
    },
    [`${resolvePendingChatMessage}`]: (state, action) => {
      const { roomID, messageID } = action.payload

      if (!(roomID in state.pendingMessages)) return { ...state }

      // find and remove the messageID in the messages
      let updatedMessages = [...state.pendingMessages[roomID]]
      updatedMessages = updatedMessages.filter(
        message => message.id !== messageID
      )

      return {
        ...state,
        pendingMessages: {
          ...state.pendingMessages,
          [roomID]: updatedMessages,
        },
      }
    },
    [`${receiveChatMessage}`]: (state, action) => {
      const { roomID, message } = action.payload

      const updatedMessages = state.messages[roomID]
        ? [...state.messages[roomID]]
        : []
      updatedMessages.push(message)

      return {
        ...state,
        messages: {
          ...state.messages,
          [roomID]: updatedMessages,
        },
      }
    },
    [`${updateChatMessage}`]: (state, action) => {
      const { updatedChatRoom, updatedMessage } = action.payload

      const updatedRoomDetails = {
        ...state.roomDetails,
        [updatedChatRoom.id]: updatedChatRoom,
      }

      // find the updated message in the room
      const messagesInRoom = state.messages[updatedChatRoom.id]

      for (let i = messagesInRoom.length - 1; i > 0; i--) {
        if (messagesInRoom[i].id === updatedMessage.id) {
          return {
            ...state,
            messages: {
              ...state.messages,
              [updatedChatRoom.id]: [
                ...messagesInRoom.slice(0, i),
                updatedMessage,
                ...messagesInRoom.slice(i + 1),
              ],
            },
            roomDetails: updatedRoomDetails,
          }
        }
      }

      return { ...state }
    },
    [`${updateChatRoomDetails}`]: (state, action) => ({
      ...state,
      roomDetails: {
        ...state.roomDetails,
        ...action.payload,
      },
    }),
    [`${updateDefaultChatRooms}`]: (state, action) => ({
      ...state,
      defaultRooms: {
        ...state.defaultRooms,
        ...action.payload,
      },
    }),
    [`${updateEventChatRooms}`]: (state, action) => ({
      ...state,
      eventRooms: {
        ...state.eventRooms,
        ...action.payload,
      },
    }),
    // receives a default chat room from the socket
    [`${receiveDefaultChatRoom}`]: (state, action) => ({
      ...state,
      defaultRooms: {
        ...state.defaultRooms,
        ids: [
          action.payload,
          ...state.defaultRooms.ids.filter(id => id !== action.payload),
        ],
      },
    }),
    [`${receiveEventChatRoom}`]: (state, action) => ({
      ...state,
      eventRooms: {
        ...state.eventRooms,
        ids: [
          action.payload,
          ...state.eventRooms.ids.filter(id => id !== action.payload),
        ],
      },
    }),
  },
  defaultState
)
