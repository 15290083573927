/**
 * @file Utils of all the generic helper functions
 * @author Alwyn Tan
 */

/**
 * converts array of objects to objects
 * Requirements: must have "id" as a unique key
 * Eg: [{id: 1, value: 'test'}] -> {1: {value: 'test'}}
 * @param {Array} arr Array of Objects
 */
export const normalizeArrayOfObjects = arr => {
  return arr.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {})
}

export const normalizeObjectArray = arr => {
  return arr.reduce(
    (acc, curr) => ({
      normalized: { ...acc.normalized, [curr.id]: curr },
      ids: [...acc.ids, curr.id],
    }),
    { normalized: {}, ids: [] }
  )
}
