// v2 version of event. This version supports loading more events

/**
 * @file Reducers for events
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import clamp from 'lodash/clamp'
import {
  setCanLoadMorePastEvents,
  setCanLoadMoreUpcomingEvents,
  setEventMatches,
  setPastEvents,
  setUpcomingEvents,
  updateEventDetails,
  updateCreateEventStore,
  resetCreateEventStore,
} from '../actions/events'

const CREATE_EVENT_SEGMENTS = 5

const defaultState = {
  canLoadMorePastEvents: true,
  canLoadMoreUpcomingEvents: true,
  pastEvents: [], // active = false
  upcomingEvents: [], // active = true
  eventDetails: {}, // {id -> Details}
  matches: {},
  createEventStore: {
    progress: 0,
    segments: CREATE_EVENT_SEGMENTS,
    formData: {
      settings: {
        showGuestList: true,
        showFunFacts: true,
        showProfessions: true,
        showQuestion: true,
      },
    },
  }, // the store for event creation
  // cancelledEvents?
}

export default handleActions(
  {
    [`${setCanLoadMorePastEvents}`]: (state, action) => ({
      ...state,
      canLoadMorePastEvents: action.payload,
    }),
    [`${setCanLoadMoreUpcomingEvents}`]: (state, action) => ({
      ...state,
      canLoadMoreUpcomingEvents: action.payload,
    }),
    [`${setPastEvents}`]: (state, action) => ({
      ...state,
      pastEvents: action.payload,
    }),
    [`${setUpcomingEvents}`]: (state, action) => ({
      ...state,
      upcomingEvents: action.payload,
    }),
    [`${updateEventDetails}`]: (state, action) => ({
      ...state,
      eventDetails: {
        ...state.eventDetails,
        [action.payload.id]: action.payload,
      },
    }),
    [`${setEventMatches}`]: (state, action) => ({
      ...state,
      matches: action.payload,
    }),
    [`${updateCreateEventStore}`]: (state, action) => ({
      ...state,
      createEventStore: {
        ...state.createEventStore,
        progress: clamp(action.payload.progress, 0, CREATE_EVENT_SEGMENTS - 1),
        formData: {
          ...state.createEventStore.formData,
          ...action.payload.data,
        },
      },
    }),
    [`${resetCreateEventStore}`]: state => ({
      ...state,
      createEventStore: defaultState.createEventStore,
    }),
  },
  defaultState
)
