/**
 * @file Hotline Calls related sagas
 * @author Alwyn Tan
 */

import toast from 'react-hot-toast'
import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  loadAvailableHotlines,
  updateHotlineDetails,
  registerCall,
  unregisterCall,
  updateCallDetails,
  loadUpcomingHotlineCalls,
  updateUpcomingHotlineCalls,
  updateAvailableHotlines,
  getHotline,
} from '../actions/calls'
import {
  GET_HOTLINE_URL,
  LOAD_AVAILABLE_CALLS_URL,
  LOAD_UPCOMING_CALLS_URL,
  REGISTER_CALL_URL,
  UNREGISTER_CALL_URL,
} from '../constants'
import { normalizeArrayOfObjects, normalizeObjectArray } from '../utils'
import { get, post } from '../utils/saga-fetch'

// loads the specified hotline and all the available calls it has
function* startGetHotline(action) {
  const id = action.payload

  const { hotline, hotlineCalls } = yield get(`${GET_HOTLINE_URL}/${id}`)

  yield put(updateHotlineDetails(normalizeObjectArray([hotline]).normalized))
  yield put(updateCallDetails(normalizeObjectArray(hotlineCalls).normalized))
}

function* startLoadAvailableHotlines() {
  const availableHotlines = yield select(state => state.calls.available)

  if (availableHotlines.canLoadMore) {
    yield put(updateAvailableHotlines({ loading: true }))

    let queryParams = ''
    if (availableHotlines.ids.length > 0) {
      queryParams = `?lastLoadedID=${encodeURIComponent(
        availableHotlines.ids[availableHotlines.ids.length - 1]
      )}`
    }

    const { hotlines, canLoadMore } = yield get(
      `${LOAD_AVAILABLE_CALLS_URL}${queryParams}`
    )
    const { normalized, ids } = normalizeObjectArray(hotlines)

    yield put(updateHotlineDetails(normalized))
    yield put(updateAvailableHotlines({ ids, canLoadMore, loading: false }))
  }
}

function* startLoadUpcomingHotlineCalls() {
  const upcomingCalls = yield select(state => state.calls.upcoming)

  if (upcomingCalls.canLoadMore) {
    yield put(updateUpcomingHotlineCalls({ loading: true }))

    let queryParams = ''
    if (upcomingCalls.ids.length > 0) {
      queryParams = `?lastLoadedID=${encodeURIComponent(
        upcomingCalls.ids[upcomingCalls.ids.length - 1]
      )}`
    }

    const { hotlineCalls, canLoadMore } = yield get(
      `${LOAD_UPCOMING_CALLS_URL}${queryParams}`
    )

    // extract the populated hotlines from the hotlineCalls and replace it with the id
    let hotlines = {}
    const { normalized, ids } = normalizeObjectArray(hotlineCalls)
    for (const call of Object.values(normalized)) {
      hotlines = { ...hotlines, ...normalizeArrayOfObjects([call.hotline]) }
      call.hotline = call.hotline.id
    }

    yield put(updateHotlineDetails(hotlines))
    yield put(updateCallDetails(normalized))
    yield put(updateUpcomingHotlineCalls({ ids, canLoadMore, loading: false }))
  }
}

// prevent this from being spammed...
function* startRegisterCall(action) {
  const id = action.payload
  const loadingCallDetails = yield select(state => ({
    ...state.calls.callDetails[id],
    loading: true,
  }))
  yield put(updateCallDetails({ [id]: loadingCallDetails }))
  const toastID = toast.loading('Registering...')
  const { hotlineCall } = yield post(REGISTER_CALL_URL, { id })
  yield put(updateCallDetails(normalizeArrayOfObjects([hotlineCall])))
  toast.success('Registered', { id: toastID })
}

function* startUnregisterCall(action) {
  const id = action.payload
  const loadingCallDetails = yield select(state => ({
    ...state.calls.callDetails[id],
    loading: true,
  }))
  yield put(updateCallDetails({ [id]: loadingCallDetails }))
  const toastID = toast.loading('Unregistering...')
  const { hotlineCall } = yield post(UNREGISTER_CALL_URL, { id })
  yield put(updateCallDetails(normalizeArrayOfObjects([hotlineCall])))
  toast.success('Unregistered', { id: toastID })
}

function* watchGetHotline() {
  yield takeLatest(`${getHotline}`, startGetHotline)
}

function* watchLoadAvailableHotlines() {
  yield takeLatest(`${loadAvailableHotlines}`, startLoadAvailableHotlines)
}

function* watchLoadUpcomingHotlineCalls() {
  yield takeLatest(`${loadUpcomingHotlineCalls}`, startLoadUpcomingHotlineCalls)
}

function* watchRegisterCall() {
  yield takeLatest(`${registerCall}`, startRegisterCall)
}

function* watchUnregisterCall() {
  yield takeLatest(`${unregisterCall}`, startUnregisterCall)
}

export default function* callsSaga() {
  yield all([
    fork(watchGetHotline),
    fork(watchLoadAvailableHotlines),
    fork(watchLoadUpcomingHotlineCalls),
    fork(watchRegisterCall),
    fork(watchUnregisterCall),
  ])
}
