/**
 * @file sagas for matching related redux logic
 * @author Alwyn Tan
 */

import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  updateHotlineMatches,
  loadHotlineMatches,
  rateMatch,
  updateMatchDetails,
} from '../actions/match'
import { LOAD_PAST_MATCHES_URL, RATE_MATCH_URL } from '../constants'
import { get, post } from '../utils/saga-fetch'
import { normalizeObjectArray } from '../utils'

// everytime this is called, it loads from the most recent loaded
function* startLoadHotlineMatches() {
  const hotlineCallMatches = yield select(
    state => state.match.hotlineCallMatches
  )

  if (hotlineCallMatches.canLoadMore) {
    yield put(updateHotlineMatches({ loading: true }))

    let queryParams = `?eventType=hotlineCall`
    if (hotlineCallMatches.ids.length > 0)
      queryParams += `&lastLoadedID=${encodeURIComponent(
        hotlineCallMatches.ids[hotlineCallMatches.ids.length - 1]
      )}`

    const { matches, canLoadMore } = yield get(
      `${LOAD_PAST_MATCHES_URL}${queryParams}`
    )

    const { normalized, ids } = normalizeObjectArray(matches)

    yield put(updateMatchDetails(normalized))
    yield put(
      updateHotlineMatches({
        ids: [...hotlineCallMatches.ids, ...ids],
        loading: false,
        canLoadMore: !!canLoadMore,
      })
    )
  }
}

function* startRateMatch(action) {
  const { id, rating } = action.payload

  const { match } = yield post(RATE_MATCH_URL, { id, rating })
  const { normalized } = normalizeObjectArray([match])
  yield put(updateMatchDetails(normalized))
}

function* watchLoadHotlineMatches() {
  yield takeLatest(`${loadHotlineMatches}`, startLoadHotlineMatches)
}

function* watchRateMatch() {
  yield takeLatest(`${rateMatch}`, startRateMatch)
}

export default function* matchSaga() {
  yield all([fork(watchLoadHotlineMatches), fork(watchRateMatch)])
}
