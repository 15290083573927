/**
 * @file Authentication related sagas
 * @author Alwyn Tan
 */

import {
  all,
  fork,
  put,
  take,
  takeLatest,
  select,
  call,
} from 'redux-saga/effects'
import toast from 'react-hot-toast'
import { serialize } from 'object-to-formdata'
import dayjs from 'dayjs'
import { USER_UPDATE_URL, USER_INFO_URL } from '../constants'
import {
  getUserInfo,
  setUserInfo,
  onboardUser,
  setLoadingUser,
  updateUser,
} from '../actions/user'
import { setCurrentUser } from '../actions/auth'
import { get } from '../utils/saga-fetch'

const loadingWrapper = fn =>
  function* wrapper(action) {
    yield put(setLoadingUser(true))
    yield call(fn, action)
    yield put(setLoadingUser(false))
  }

function* startUpdateUser(action) {
  const userData = action.payload
  const accessToken = yield select(state => state.auth.accessToken)

  const toastID = toast.loading('Updating your profile')

  try {
    const { user } = yield fetch(USER_UPDATE_URL, {
      method: 'POST',
      body: serialize(userData),
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(response => response.json())
    yield put(setCurrentUser(user))
    toast.success('Profile Updated', { id: toastID })
  } catch (err) {
    console.error(err)
    toast.error('Error updating profile', { id: toastID })
  }
}

function* startGetUserInfo(action) {
  const { id } = action.payload

  const { user } = yield get(`${USER_INFO_URL}?id=${encodeURIComponent(id)}`)
  let currentUserInfos = yield select(state => state.user.info)

  if (user) {
    currentUserInfos = { ...currentUserInfos, [id]: user }
    yield put(setUserInfo(currentUserInfos))
  } else {
    currentUserInfos = { ...currentUserInfos, [id]: { notExist: true } }
    yield put(setUserInfo(currentUserInfos))
  }
}

function* watchUpdateUser() {
  yield takeLatest(`${updateUser}`, loadingWrapper(startUpdateUser))
}

function* watchGetUserInfo() {
  yield takeLatest(`${getUserInfo}`, loadingWrapper(startGetUserInfo))
}

function* watchOnboardUser() {
  while (true) {
    yield take(`${onboardUser}`)
    const formData = yield select(
      state => state.user.onboardingUserStore.formData
    )
    // force the date into an actual dayjs object
    yield put(
      updateUser({
        ...formData,
        birthDate: dayjs(formData.birthDate).toISOString(),
      })
    )
  }
}

export default function* userSaga() {
  yield all([
    fork(watchUpdateUser),
    fork(watchGetUserInfo),
    fork(watchOnboardUser),
  ])
}
