// v2 version of events. Migrate event to events when complete

import {
  all,
  fork,
  take,
  select,
  put,
  takeLatest,
  call,
} from 'redux-saga/effects'
import { serialize } from 'object-to-formdata'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
import {
  GET_EVENT_DETAILS_URL,
  REGISTER_EVENT_URL,
  UPDATE_EVENT_ADDITIONAL_INFO,
  GET_EVENT_MATCHES_URL,
  CONFIRM_EVENT_MATCHES_URL,
  LOAD_REGISTERED_EVENTS_URL,
  CREATE_EVENT_URL,
  UPDATE_EVENT_URL,
  CANCEL_EVENT_URL,
  CANCEL_EVENT_REGISTRATION_URL,
  REGISTER_EVENT_WAITLIST_URL,
  CANCEL_EVENT_WAITLIST_URL,
} from '../constants'
import { post, get } from '../utils/saga-fetch'
import {
  initEvents,
  setPastEvents,
  setUpcomingEvents,
  getEventDetails,
  updateEventDetails,
  registerEvent,
  updateEvent,
  cancelEventRegistration,
  loadUpcomingEvents,
  loadPastEvents,
  setCanLoadMorePastEvents,
  setCanLoadMoreUpcomingEvents,
  updateEventAdditionalInfo,
  getEventMatches,
  setEventMatches,
  confirmEventMatches,
  createNewEvent,
  cancelEvent,
  registerEventWaitlist,
  cancelEventWaitlist,
} from '../actions/events'
import { newStripeOrder } from '../actions/stripe'

function* startLoadingPastEvents() {
  const currentPastEvents = yield select(state => state.events.pastEvents)

  const responseJSON = yield post(LOAD_REGISTERED_EVENTS_URL, {
    sinceEvent: currentPastEvents[currentPastEvents.length - 1],
    isActive: false,
  })

  if (responseJSON) {
    // const currentPastEvents = yield select(state => state.events.pastEvents)
    yield put(setPastEvents([...currentPastEvents, ...responseJSON.events]))
    yield put(setCanLoadMorePastEvents(responseJSON.hasMore))
  }
}

function* startLoadingUpcomingEvents() {
  const currentUpcomingEvents = yield select(
    state => state.events.upcomingEvents
  )

  const responseJSON = yield post(LOAD_REGISTERED_EVENTS_URL, {
    sinceEvent: currentUpcomingEvents[currentUpcomingEvents.length - 1],
    isActive: true,
  })

  if (responseJSON) {
    yield put(
      setUpcomingEvents([...currentUpcomingEvents, ...responseJSON.events])
    )
    yield put(setCanLoadMoreUpcomingEvents(responseJSON.hasMore))
  }
}

function* setLoadedEvent(event, id) {
  if (event) {
    yield put(updateEventDetails({ ...event }))
  } else {
    yield put(updateEventDetails({ id, notFound: true }))
  }
}

function* setLoadingEvent(id) {
  const currentEventDetails = yield select(
    state => state.events.eventDetails[id] || {}
  )

  yield put(updateEventDetails({ ...currentEventDetails, loading: true, id }))
}

function* fetchEventDetails(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)
  const eventDetails = yield get(`${GET_EVENT_DETAILS_URL}/${eventID}`)
  yield call(setLoadedEvent, eventDetails, eventID)
}

// TODO: handle errors
function* startRegisterEvent(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)
  const toastID = toast.loading('Registering')

  const { event, paymentRequired } = yield post(REGISTER_EVENT_URL, {
    eventID,
  })

  if (paymentRequired) {
    yield put(newStripeOrder({ eventID }))
  } else {
    toast.success('Registered', { id: toastID })
    yield call(setLoadedEvent, event, eventID)
  }
}

function* startCancelEventRegistration(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)

  const { event, refundRequired } = yield post(CANCEL_EVENT_REGISTRATION_URL, {
    eventID,
  })

  if (refundRequired) {
    // todo later or maybe put some placeholder here to return
  } else {
    yield call(setLoadedEvent, event, eventID)
  }
}

function* startUpdateEventAdditionalInfo(action) {
  const { additionalInfo, eventID } = action.payload

  const eventDetails = yield post(UPDATE_EVENT_ADDITIONAL_INFO, {
    additionalInfo,
    eventID,
  })

  yield call(setLoadedEvent, eventDetails, eventID)
}

function* startGetEventMatches(action) {
  const id = action.payload

  // loading
  let existingMatches = yield select(state => state.events.matches)
  yield put(setEventMatches({ ...existingMatches, [id]: { loading: true } }))

  // received
  const { matches, isFixed } = yield get(`${GET_EVENT_MATCHES_URL}/${id}`)
  existingMatches = yield select(state => state.events.matches)
  yield put(
    setEventMatches({ ...existingMatches, [id]: { data: matches, isFixed } })
  )
}

function* startConfirmEventMatches(action) {
  const { matches, eventID } = action.payload

  // loading
  let existingMatches = yield select(state => state.events.matches)
  yield put(
    setEventMatches({
      ...existingMatches,
      [eventID]: { ...existingMatches[eventID], loading: true },
    })
  )

  const { success } = yield post(`${CONFIRM_EVENT_MATCHES_URL}`, {
    matches,
    eventID,
  })

  if (success) {
    existingMatches = yield select(state => state.events.matches)
    yield put(
      setEventMatches({
        ...existingMatches,
        [eventID]: {
          ...existingMatches[eventID],
          loading: false,
          isFixed: true,
        },
      })
    )
  }

  // todo: add error handling
}

// @Alwyn what should we rename this?
function* startCreateNewEvent(action) {
  const id = action.payload
  const formData = yield select(state => state.events.createEventStore.formData)
  const accessToken = yield select(state => state.auth.accessToken)
  const userID = yield select(state => state.auth.user?.id)

  const dataToSubmit = serialize(formData)
  dataToSubmit.append('host', userID)

  if (id) dataToSubmit.append('id', id)
  const ENDPOINT_URL = id ? UPDATE_EVENT_URL : CREATE_EVENT_URL

  try {
    const { eventID } = yield fetch(ENDPOINT_URL, {
      method: 'POST',
      body: dataToSubmit,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(response => response.json())

    navigate(`/app/events/${eventID}`)
  } catch (err) {
    console.error(err)
  }
}

function* startCancelEvent(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)

  const { event } = yield post(CANCEL_EVENT_URL, {
    eventID,
  })

  yield call(setLoadedEvent, event, eventID)
}

function* startRegisterEventWaitlist(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)
  const { event } = yield post(REGISTER_EVENT_WAITLIST_URL, {
    eventID,
  })

  yield call(setLoadedEvent, event, eventID)
}

function* startCancelEventWaitlist(action) {
  const eventID = action.payload
  yield call(setLoadingEvent, eventID)

  const { event } = yield post(CANCEL_EVENT_WAITLIST_URL, {
    eventID,
  })

  yield call(setLoadedEvent, event, eventID)
}

function* watchRegisterEvent() {
  yield takeLatest(`${registerEvent}`, startRegisterEvent)
}

function* watchCancelEventRegistration() {
  yield takeLatest(`${cancelEventRegistration}`, startCancelEventRegistration)
}

function* watchRegisterEventWaitlist() {
  yield takeLatest(`${registerEventWaitlist}`, startRegisterEventWaitlist)
}

function* watchCancelEventWaitlist() {
  yield takeLatest(`${cancelEventWaitlist}`, startCancelEventWaitlist)
}

function* watchGetEventDetails() {
  yield takeLatest(`${getEventDetails}`, fetchEventDetails)
}

function* watchLoadUpcomingEvents() {
  yield takeLatest(`${loadUpcomingEvents}`, startLoadingUpcomingEvents)
}

function* watchLoadPastEvents() {
  yield takeLatest(`${loadPastEvents}`, startLoadingPastEvents)
}

function* watchConfirmEventMatches() {
  yield takeLatest(`${confirmEventMatches}`, startConfirmEventMatches)
}

function* watchUpdateEventAdditionalInfo() {
  yield takeLatest(
    `${updateEventAdditionalInfo}`,
    startUpdateEventAdditionalInfo
  )
}

function* watchGetEventMatches() {
  yield takeLatest(`${getEventMatches}`, startGetEventMatches)
}

// initialize and hydrate the event home data
function* eventHomeFlow() {
  // 1. load initial past and future events. 5 at a time.
  // 2. listen for more loads
  yield take(`${initEvents}`)

  yield fork(startLoadingPastEvents)
  yield fork(startLoadingUpcomingEvents)
}

function* watchCreateNewEvent() {
  yield takeLatest(`${createNewEvent}`, startCreateNewEvent)
}

function* watchUpdateEvent() {
  yield takeLatest(`${updateEvent}`, startCreateNewEvent)
}

function* watchCancelEvent() {
  yield takeLatest(`${cancelEvent}`, startCancelEvent)
}

export default function* eventsSaga() {
  yield all([
    fork(eventHomeFlow),
    fork(watchGetEventDetails),
    fork(watchGetEventMatches),
    fork(watchLoadUpcomingEvents),
    fork(watchLoadPastEvents),
    fork(watchConfirmEventMatches),
    fork(watchRegisterEvent),
    fork(watchCancelEventRegistration),
    fork(watchUpdateEventAdditionalInfo),
    fork(watchCreateNewEvent),
    fork(watchUpdateEvent),
    fork(watchCancelEvent),
    fork(watchRegisterEventWaitlist),
    fork(watchCancelEventWaitlist),
  ])
}
