/**
 * @file Sagas for chat only
 * @author Alwyn Tan
 */

import { eventChannel } from 'redux-saga'
import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects'
import io from 'socket.io-client'
import { navigate } from 'gatsby'
import {
  chatInit,
  chatSocketConnected,
  joinChatRoom,
  receiveChatMessage,
  loadChatRooms,
  setChatRoom,
  sendChatMessage,
  receivePendingChatMessage,
  resolvePendingChatMessage,
  receiveLoadedChatMessages,
  loadChatMessages,
  createChatRoomWith,
  seenChat,
  updateChatMessage,
  updateDefaultChatRooms,
  updateEventChatRooms,
  updateChatRoomDetails,
  receiveDefaultChatRoom,
  receiveEventChatRoom,
} from '../actions/chat'
import { post } from '../utils/saga-fetch'
import {
  SOCKET_LOAD_CHAT_ROOMS,
  SOCKET_JOIN_CHAT_ROOM,
  SOCKET_CHAT_MESSAGE,
  SOCKET_CHAT_MESSAGE_RESOLVED,
  SOCKET_CHAT_MESSAGE_PENDING,
  SOCKET_LOAD_CHAT_MESSAGES,
  SOCKET_BASE_API_URL,
  SOCKET_CHAT_ROOM,
  CREATE_CHAT_ROOM_WITH_URL,
  SOCKET_CHAT_SEEN,
  CHAT_ROOM_TYPE,
} from '../constants'
import { normalizeObjectArray } from '../utils'

const connectSocket = accessToken => {
  const socket = io(SOCKET_BASE_API_URL)

  return new Promise((resolve, reject) => {
    socket.on('connect', () => {
      socket
        .emit('authenticate', { token: accessToken }) // send the jwt
        .on('authenticated', () => {
          resolve(socket)
        })
        .on('unauthorized', msg => {
          socket.disconnect()
          reject(new Error(`Unauthorized: ${msg}`))
        })
    })
  })
}

function* fetchChatRooms(socket, action) {
  const { roomType } = action.payload

  let existingRoomsSelector
  let roomUpdateAction

  switch (roomType) {
    case CHAT_ROOM_TYPE.DEFAULT:
      existingRoomsSelector = state => state.chat.defaultRooms
      roomUpdateAction = updateDefaultChatRooms
      break
    case CHAT_ROOM_TYPE.EVENT:
      existingRoomsSelector = state => state.chat.eventRooms
      roomUpdateAction = updateEventChatRooms
      break
    default:
      return
  }

  const existingRooms = yield select(existingRoomsSelector)
  const lastRoomID = existingRooms.ids[existingRooms.ids.length - 1]

  const channel = eventChannel(emit => {
    socket.emit(
      SOCKET_LOAD_CHAT_ROOMS,
      { sinceID: lastRoomID, roomType },
      ({ chatRooms, canLoadMore }) => {
        const { normalized, ids } = normalizeObjectArray(chatRooms)
        emit([
          updateChatRoomDetails(normalized),
          roomUpdateAction({
            ids: [...existingRooms.ids, ...ids],
            loading: false,
            canLoadMore: !!canLoadMore,
          }),
        ])
      }
    )

    return () => {}
  })

  const channelActions = yield take(channel)
  for (const channelAction of channelActions) yield put(channelAction)
  channel.close()
}

function* fetchChatMessages(socket, action) {
  const { roomID, oldestLoadedMessage } = action.payload
  const channel = eventChannel(emit => {
    socket.emit(
      SOCKET_LOAD_CHAT_MESSAGES,
      { roomID, oldestLoadedMessage },
      ack => {
        if (ack?.messages)
          emit(
            receiveLoadedChatMessages({
              roomID: ack?.roomID,
              messages: ack?.messages,
            })
          )
        else navigate('/app/chat')
      }
    )

    return () => {}
  })

  const channelAction = yield take(channel)
  yield put(channelAction)
  channel.close()
}

// todo remove chat room
function* changeChatRoom(socket, action) {
  const roomID = action.payload

  // load chat messages if the room hasnt been set yet
  const isChatRoomLoaded = yield select(state => roomID in state.chat.messages)
  if (!isChatRoomLoaded) yield put(loadChatMessages({ roomID }))

  const channel = eventChannel(emit => {
    socket.emit(SOCKET_JOIN_CHAT_ROOM, roomID, ({ roomDetails }) => {
      emit(setChatRoom({ roomDetails }))
    })

    return () => {}
  })

  const channelAction = yield take(channel)
  yield put(channelAction)
  channel.close()
}

function* startSeenChat(socket, action) {
  const { roomID } = action.payload

  // prevent a socket call if the curren user already seen the latest message
  const lastMessage = yield select(
    state => state.chat.roomDetails?.[roomID]?.lastMessage
  )
  if (!lastMessage) return
  const currentUserID = yield select(state => state.auth.user?.id)

  if (lastMessage?.seen && !lastMessage.seen.some(id => id === currentUserID)) {
    const channel = eventChannel(emit => {
      socket.emit(
        SOCKET_CHAT_SEEN,
        roomID,
        ({ updatedMessage, updatedChatRoom, error }) => {
          if (updatedMessage) {
            emit(updateChatMessage({ updatedMessage, updatedChatRoom }))
          } else if (error) {
            console.error(error)
          }
        }
      )

      return () => {}
    })

    const channelAction = yield take(channel)
    yield put(channelAction)
    channel.close()
  }
}

// handle all the server -> client pings
function* read(socket) {
  const channel = eventChannel(emit => {
    socket.on(SOCKET_CHAT_MESSAGE_PENDING, ({ roomID, message }) => {
      emit(receivePendingChatMessage({ roomID, message }))
    })

    socket.on(SOCKET_CHAT_MESSAGE_RESOLVED, ({ roomID, messageID }) => {
      emit(resolvePendingChatMessage({ roomID, messageID }))
    })

    socket.on(SOCKET_CHAT_MESSAGE, ({ roomID, message }) => {
      emit(receiveChatMessage({ roomID, message }))
    })

    socket.on(SOCKET_CHAT_ROOM, ({ roomDetails }) => {
      emit(updateChatRoomDetails({ [roomDetails.id]: roomDetails }))

      if (roomDetails.type === CHAT_ROOM_TYPE.DEFAULT) {
        emit(receiveDefaultChatRoom(roomDetails.id))
      } else if (roomDetails.type === CHAT_ROOM_TYPE.EVENT) {
        emit(receiveEventChatRoom(roomDetails.id))
      }
    })

    return () => {}
  })

  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* write(socket) {
  while (true) {
    const { payload } = yield take(`${sendChatMessage}`)
    socket.emit(SOCKET_CHAT_MESSAGE, payload)
  }
}

function* makeChatRoom(action) {
  const otherUser = action.payload

  const { roomID } = yield post(CREATE_CHAT_ROOM_WITH_URL, { with: otherUser })

  if (roomID) navigate(`/app/chat/${roomID}`)
  // todo: add error handling for when rooms cant be made
}

function* watchLoadChatMessages(socket) {
  yield takeEvery(`${loadChatMessages}`, fetchChatMessages, socket)
}

function* watchLoadChatRooms(socket) {
  yield takeLatest(`${loadChatRooms}`, fetchChatRooms, socket)
}

function* watchSeenChat(socket) {
  yield takeLatest(`${seenChat}`, startSeenChat, socket)
}

function* watchCreateChatRoom() {
  yield takeLatest(`${createChatRoomWith}`, makeChatRoom)
}

// auto load chat list
// action to load the selected chat list
// auto load messages after click, listen to send message....
export default function* chatSaga() {
  yield fork(watchCreateChatRoom)
  // make sure to take chat init only once
  yield take(`${chatInit}`)
  const accessToken = yield select(state => state.auth.accessToken)
  const socket = yield call(connectSocket, accessToken)

  if (socket) {
    yield put(chatSocketConnected())

    // set up listeners
    yield fork(read, socket)
    yield fork(write, socket)
    yield fork(watchLoadChatMessages, socket)
    yield fork(watchLoadChatRooms, socket)
    yield fork(watchSeenChat, socket)
    yield takeLatest(`${joinChatRoom}`, changeChatRoom, socket)

    // TODO: add a cancellable redux call to cancel task and close socket
  }
}
