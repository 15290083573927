/**
 * @file Action creators for authentication
 * @author Alwyn Tan
 */

const { createAction } = require('redux-actions')

export const setCurrentUser = createAction('SET_CURRENT_USER')
export const setAccessToken = createAction('SET_ACCESS_TOKEN')

export const setAuthError = createAction('SET_AUTH_ERROR')
export const setAuthLoading = createAction('SET_AUTH_LOADING')

export const silentLogin = createAction('SILENT_LOGIN') // uses refresh tokens
export const loginAccount = createAction('LOGIN_ACCOUNT')
export const registerAccount = createAction('REGISTER_ACCOUNT')
export const verifyAccount = createAction('VERIFY_ACCOUNT')
export const getAccount = createAction('GET_ACCOUNT') // actually the same as retrieving user, but for auth purposes
export const setAuthPhoneNumber = createAction('SET_AUTH_PHONE_NUMBER')
export const setAuthSuccessCallback = createAction('SET_AUTH_SUCCESS_CALLBACK')
export const verifyCode = createAction('VERIFY_CODE')
export const changePassword = createAction('CHANGE_PASSWORD')
export const setVerified = createAction('SET_VERIFIED')
export const setVerificationCode = createAction('SET_VERIFICATION_CODE')
export const setResetPasswordSuccess = createAction(
  'SET_RESET_PASSWORD_SUCCESS'
)
export const sendVerificationCode = createAction('SEND_VERIFICATION_CODE')
export const getNextResendVerificationDate = createAction(
  'GET_NEXT_RESEND_VERIFICATION_DATE'
)
export const setNextResendVerificationDate = createAction(
  'SET_NEXT_RESEND_VERIFICATION_DATE'
)

export const signOut = createAction('SIGN_OUT')
