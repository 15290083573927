/**
 * @file Authentication related sagas
 * @author Alwyn Tan
 */

import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import {
  setCurrentUser,
  setAccessToken,
  silentLogin,
  setAuthError,
  setAuthLoading,
  signOut,
  loginAccount,
  registerAccount,
  verifyAccount,
  getAccount,
  setAuthPhoneNumber,
  setAuthSuccessCallback,
  sendVerificationCode,
  changePassword,
  verifyCode,
  setVerified,
  setVerificationCode,
  setResetPasswordSuccess,
  getNextResendVerificationDate,
  setNextResendVerificationDate,
} from '../actions/auth'
import {
  USER_ME_URL,
  REFRESH_TOKEN_URL,
  SIGN_OUT_URL,
  LOGIN_ACCOUNT_URL,
  REGISTER_ACCOUNT_URL,
  VERIFY_ACCOUNT_URL,
  USER_INFO_URL,
  SEND_VERIFICATION_CODE_URL,
  VERIFY_CODE_URL,
  CHANGE_PASSWORD_URL,
  NEXT_RESEND_VERIFICATION_DATE_URL,
} from '../constants'
import { get, post } from '../utils/saga-fetch'
import { chatInit } from '../actions/chat'
import { updateAbility } from '../casl/ability'

const subscribeToPush = async accessToken => {
  // asks for notif permissions
  if (accessToken && 'Notification' in window) {
    if (Notification.permission !== 'granted') {
      const perm = await Notification.requestPermission()
      if (perm !== 'granted') return
    }

    navigator.serviceWorker.controller.postMessage({
      type: 'SUBSCRIBE_PUSH_NOTIFICATION',
      accessToken,
    })
  }
}

function* startJWTExpiryTimeout(expiry) {
  if (expiry) {
    const channel = eventChannel(emit => {
      setTimeout(() => {
        emit(silentLogin())
      }, expiry)

      return () => {}
    })

    const channelAction = yield take(channel)
    yield put(channelAction)
    channel.close()
  }
}

function* fetchCurrentUser(accessToken) {
  if (!accessToken) {
    yield put(setCurrentUser(null))
    return
  }

  try {
    const { user } = yield fetch(USER_ME_URL, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then(response => response.json())
    yield put(setCurrentUser(user))
  } catch (err) {
    yield put(setCurrentUser(null))
  }
}

function* postAuth(user) {
  updateAbility(user.roles)
  yield call(fetchCurrentUser, user.accessToken)
  yield put(
    setAccessToken({
      accessToken: user.accessToken,
      userVerified: user.userVerified,
      emailVerified: user.emailVerified,
    })
  )
  yield fork(startJWTExpiryTimeout, user.expiry)

  if (user.accessToken && user.userVerified) {
    const authSuccessCallback = yield select(
      state => state.auth.authSuccessCallback
    )
    if (authSuccessCallback) authSuccessCallback()
    yield setAuthSuccessCallback(null)
    // initializes chat too
    yield put(chatInit())
  }
}

function* startSilentLogin() {
  yield put(setAuthLoading(true))
  try {
    const user = yield fetch(REFRESH_TOKEN_URL, {
      credentials: 'include',
    }).then(response => response.json())
    yield fork(postAuth, user)
  } catch (err) {
    console.error(`token error: ${err}`)
    yield put(setAccessToken(null))
  }
}

function* startSignOut() {
  const accessToken = yield select(state => state.auth.accessToken)
  try {
    // notification unsubscribe on logout
    navigator.serviceWorker.controller.postMessage({
      type: 'UNSUBSCRIBE_PUSH_NOTIFICATION',
      accessToken,
    })

    yield fetch(SIGN_OUT_URL, { credentials: 'same-origin' })
    window.location.reload()
  } catch (err) {
    console.error(err)
  }
}

function* startLoginAccount(action) {
  yield put(setAuthLoading(true))
  const { password } = action.payload
  const phoneNumber = yield select(state => state.auth.authPhoneNumber)

  try {
    const user = yield fetch(LOGIN_ACCOUNT_URL, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ phoneNumber, password }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
    if (user?.error) {
      yield put(setAuthError('Invalid phone number or password'))
    } else {
      yield fork(postAuth, user)
      yield call(subscribeToPush, user.accessToken)
    }
  } catch (err) {
    console.error(err)
    yield put(setAuthError('Invalid phone number or password'))
  }
}

function* startRegisterAccount(action) {
  yield put(setAuthLoading(true))
  const { name, password } = action.payload
  const phoneNumber = yield select(state => state.auth.authPhoneNumber)

  try {
    const user = yield fetch(REGISTER_ACCOUNT_URL, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ name, phoneNumber, password }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
    if (user?.error) yield put(setAuthError('Invalid phone number or password'))
    else yield fork(postAuth, user)
  } catch (err) {
    console.error(err)
    yield put(setAuthError('Error: Please try again later'))
  }
}

function* startVerifyAccount(action) {
  yield put(setAuthLoading(true))
  const { verificationCode } = action.payload

  const user = yield post(VERIFY_ACCOUNT_URL, { verificationCode })

  if (user?.error) yield put(setAuthError('Invalid verification code'))
  else yield fork(postAuth, user)
}

function* startGetAccount(action) {
  yield put(setAuthLoading(true))
  const { phoneNumber } = action.payload

  const { user } = yield get(
    `${USER_INFO_URL}?phoneNumber=${encodeURIComponent(phoneNumber)}`
  )
  if (user?.error) {
    yield put(setAuthError('Invalid Phone Number'))
    return
  }
  if (user) yield put(setCurrentUser({ ...user, pendingLogin: true }))
  yield put(setAuthPhoneNumber(phoneNumber))
  yield put(setAuthLoading(false))
}

// ask the backend to create a temporary verification code
function* startSendVerificationCode() {
  yield put(setAuthLoading(true))
  const phoneNumber = yield select(state => state.auth.user.phoneNumber)
  const { nextResendDate } = yield post(SEND_VERIFICATION_CODE_URL, {
    phoneNumber,
  })
  if (nextResendDate) yield put(setNextResendVerificationDate(nextResendDate))
  yield put(setAuthLoading(false))
}

function* startChangePassword(action) {
  yield put(setAuthLoading(true))
  const phoneNumber = yield select(state => state.auth.authPhoneNumber)
  const { oldPassword, newPassword } = action.payload
  let success = false

  if (oldPassword) {
    // use oldPassword -> newPassword flow
    const response = yield post(CHANGE_PASSWORD_URL, {
      oldPassword,
      newPassword,
      phoneNumber,
    })
    success = response?.success
  } else {
    const verificationCode = yield select(state => state.auth.verificationCode)
    const response = yield post(CHANGE_PASSWORD_URL, {
      verificationCode,
      newPassword,
      phoneNumber,
    })
    success = response?.success
  }

  if (success) {
    yield put(setResetPasswordSuccess(true))
  } else {
    yield put(setAuthError('Unable to change password'))
  }
  yield put(setAuthLoading(false))
}

function* startVerifyCode(action) {
  yield put(setAuthLoading(true))
  const phoneNumber = yield select(state => state.auth.authPhoneNumber)
  const verificationCode = action.payload
  const { verified } = yield post(VERIFY_CODE_URL, {
    verificationCode,
    phoneNumber,
  })

  if (!verified) {
    yield put(setAuthError('Invalid Verification Code'))
  } else {
    yield put(setVerified(true))
    yield put(setVerificationCode(verificationCode))
  }
  yield put(setAuthLoading(false))
}

function* startGetNextResendVerificationDate() {
  yield put(setAuthLoading(true))

  const phoneNumber = yield select(state => state.auth.user.phoneNumber)
  const { nextResendDate } = yield post(NEXT_RESEND_VERIFICATION_DATE_URL, {
    phoneNumber,
  })

  if (nextResendDate) yield put(setNextResendVerificationDate(nextResendDate))

  yield put(setAuthLoading(false))
}

function* watchSignOut() {
  yield takeLatest(`${signOut}`, startSignOut)
}

function* watchSilentLogin() {
  yield takeLatest(`${silentLogin}`, startSilentLogin)
}

function* watchLoginAccount() {
  yield takeLatest(`${loginAccount}`, startLoginAccount)
}

function* watchRegisterAccount() {
  yield takeLatest(`${registerAccount}`, startRegisterAccount)
}

// TODO: migrate this logic to the newer verify code logic
function* watchVerifyAccount() {
  yield takeLatest(`${verifyAccount}`, startVerifyAccount)
}

function* watchGetAccount() {
  yield takeLatest(`${getAccount}`, startGetAccount)
}

function* watchVerifyCode() {
  yield takeLatest(`${verifyCode}`, startVerifyCode)
}

function* watchSendVerificationCode() {
  yield takeLatest(`${sendVerificationCode}`, startSendVerificationCode)
}

function* watchChangePassword() {
  yield takeLatest(`${changePassword}`, startChangePassword)
}

function* watchGetNextResendVerificationDate() {
  yield takeLatest(
    `${getNextResendVerificationDate}`,
    startGetNextResendVerificationDate
  )
}

export default function* authSaga() {
  yield all([
    fork(watchSignOut),
    fork(watchSilentLogin),
    fork(watchLoginAccount),
    fork(watchRegisterAccount),
    fork(watchVerifyAccount),
    fork(watchGetAccount),
    fork(watchVerifyCode),
    fork(watchSendVerificationCode),
    fork(watchChangePassword),
    fork(watchGetNextResendVerificationDate),
  ])
}
