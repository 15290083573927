/**
 * @file Constants for the entire website
 * @author Alwyn Tan
 */

export const BASE_API_URL = process.env.GATSBY_API_SERVER
export const SOCKET_BASE_API_URL = process.env.GATSBY_SOCKET_BASE_API

// waitlist
export const JOIN_WAITLIST_URL = `${BASE_API_URL}/access/waitlist`
export const WAITLIST_REF_CHECK_URL = `${BASE_API_URL}/access/waitlist-ref-check`
export const WAITLIST_SUBMIT_SURVEY_URL = `${BASE_API_URL}/access/waitlist-survey`

// auth v2 routes
export const REFRESH_TOKEN_URL = `${BASE_API_URL}/v2/auth/refresh-token`
export const LOGIN_ACCOUNT_URL = `${BASE_API_URL}/v2/auth/login`
export const REGISTER_ACCOUNT_URL = `${BASE_API_URL}/v2/auth/register`
export const VERIFY_ACCOUNT_URL = `${BASE_API_URL}/v2/auth/verify`
export const SIGN_OUT_URL = `${BASE_API_URL}/v2/auth/sign-out`
export const SEND_VERIFICATION_CODE_URL = `${BASE_API_URL}/v2/auth/send-verification-code`
export const NEXT_RESEND_VERIFICATION_DATE_URL = `${BASE_API_URL}/v2/auth/next-resend-verification-date`
export const VERIFY_CODE_URL = `${BASE_API_URL}/v2/auth/verify-code`
export const CHANGE_PASSWORD_URL = `${BASE_API_URL}/v2/auth/change-password`
export const ADD_LINKEDIN_URL = `${BASE_API_URL}/v2/auth/linkedIn`

// events routes
export const LOAD_REGISTERED_EVENTS_URL = `${BASE_API_URL}/event/get-registered-events`
export const GET_EVENT_DETAILS_URL = `${BASE_API_URL}/event/get-event-details`
export const REGISTER_EVENT_URL = `${BASE_API_URL}/event/reserve-event`
export const UPDATE_EVENT_ADDITIONAL_INFO = `${BASE_API_URL}/event/update-additional-info`
export const GET_EVENT_MATCHES_URL = `${BASE_API_URL}/event/matches`
export const CONFIRM_EVENT_MATCHES_URL = `${BASE_API_URL}/event/confirmMatches`
export const CREATE_EVENT_URL = `${BASE_API_URL}/event/create`
export const UPDATE_EVENT_URL = `${BASE_API_URL}/event/update`
export const CANCEL_EVENT_URL = `${BASE_API_URL}/event/cancel`
export const CANCEL_EVENT_REGISTRATION_URL = `${BASE_API_URL}/event/cancel-event-registration`
export const REGISTER_EVENT_WAITLIST_URL = `${BASE_API_URL}/event/register-event-waitlist`
export const CANCEL_EVENT_WAITLIST_URL = `${BASE_API_URL}/event/cancel-event-waitlist`

// user routes
export const USER_ME_URL = `${BASE_API_URL}/user/me`
export const USER_UPDATE_URL = `${BASE_API_URL}/user/update-user`
export const USER_INFO_URL = `${BASE_API_URL}/user/info`

// stripe routes
export const STRIPE_CREATE_SESSION_URL = `${BASE_API_URL}/stripe/create-session`

// verification routes (request for perms, verify as host, etc)
export const REQUEST_SUPERPOD_VERIFICATION_URL = `${BASE_API_URL}/verification/request-superpod`
export const REQUEST_EMAIL_VERIFICATION_URL = `${BASE_API_URL}/verification/request-email`
export const VERIFY_EMAIL_URL = `${BASE_API_URL}/verification/verify-email`
export const FIND_VERIFICATIONS_URL = `${BASE_API_URL}/verification/find`

// chat rest api routes
export const CREATE_CHAT_ROOM_WITH_URL = `${BASE_API_URL}/chat/create-chat-room-with`

// chat socket routes
export const SOCKET_LOAD_CHAT_ROOMS = 'chat/getRooms'
export const SOCKET_LOAD_CHAT_MESSAGES = 'chat/getMessages'
export const SOCKET_JOIN_CHAT_ROOM = 'chat/join'
export const SOCKET_CHAT_MESSAGE = 'chat/message' // received latest message
export const SOCKET_CHAT_MESSAGE_PENDING = 'chat/message-pending'
export const SOCKET_CHAT_MESSAGE_RESOLVED = 'chat/message-resolved'
export const SOCKET_CHAT_ROOM = 'chat/room' // received latest chat room
export const SOCKET_CHAT_SEEN = 'chat/seen' // seen the message

// hotline (call) routes
export const LOAD_AVAILABLE_CALLS_URL = `${BASE_API_URL}/hotline/available`
export const LOAD_UPCOMING_CALLS_URL = `${BASE_API_URL}/hotline/upcoming`
export const GET_HOTLINE_URL = `${BASE_API_URL}/hotline`
export const REGISTER_CALL_URL = `${BASE_API_URL}/hotline/register`
export const UNREGISTER_CALL_URL = `${BASE_API_URL}/hotline/unregister`

// match routes
export const LOAD_PAST_MATCHES_URL = `${BASE_API_URL}/match`
export const RATE_MATCH_URL = `${BASE_API_URL}/match/rate`

// notification routes
export const GET_NOTIFICATIONS_URL = `${BASE_API_URL}/notification`

// pod/superpod routes
export const GET_SUPERPOD_URL = `${BASE_API_URL}/pod/superpod`

export const CHAT_MESSAGES_PER_LOAD = 15
export const CHAT_ROOMS_PER_LOAD = 10

export const ROLES = {
  ADMIN: 'admin',
  HOST: 'host',
}

export const CHAT_ROOM_TYPE = {
  DEFAULT: 'default',
  EVENT: 'event',
}

export const NOTIFICATION_TYPE = {
  STICKER: 'STICKER',
}

export const UNAUTHORIZED_ERROR_NAME = 'UNAUTHORIZED_ERROR'

export const lightTheme = {
  Accent: '#DD3F4E',
  Primary: '#F7F7FA',
  Secondary: '#FFFFFF',
  Tertiary: '#F0F1F5',
  Text: '#000000',
  Success: '#52BB8B',
  isDarkMode: false,
}

export const darkTheme = {
  Accent: '#DD3F4E',
  Primary: '#15181A',
  Secondary: '#292D2F',
  Tertiary: '#404446',
  Text: '#FFFFFF',
  Success: '#52BB8B',
  isDarkMode: true,
}
