/**
 * @file Reducers for Hotline Calls
 * @author Alwyn Tan
 */

import { handleActions } from 'redux-actions'
import {
  updateHotlineDetails,
  updateCallDetails,
  updateUpcomingHotlineCalls,
  updateAvailableHotlines,
} from '../actions/calls'

const defaultState = {
  hotlineDetails: {}, // details of all hotlines retrieved
  callDetails: {}, // details of all the calls
  loading: false,

  available: {
    ids: [],
    loading: false,
    canLoadMore: true,
  },

  upcoming: {
    ids: [],
    loading: false,
    canLoadMore: true,
  },
}

export default handleActions(
  {
    [`${updateHotlineDetails}`]: (state, action) => ({
      ...state,
      hotlineDetails: {
        ...state.hotlineDetails,
        ...action.payload,
      },
    }),
    [`${updateCallDetails}`]: (state, action) => ({
      ...state,
      callDetails: {
        ...state.callDetails,
        ...action.payload,
      },
    }),
    [`${updateUpcomingHotlineCalls}`]: (
      state,
      { payload: { ids, ...rest } }
    ) => ({
      ...state,
      upcoming: {
        ...state.upcoming,
        ids: [...state.upcoming.ids, ...(ids || [])],
        ...rest,
      },
    }),
    [`${updateAvailableHotlines}`]: (state, { payload: { ids, ...rest } }) => ({
      ...state,
      available: {
        ...state.available,
        ids: [...state.available.ids, ...(ids || [])],
        ...rest,
      },
    }),
  },
  defaultState
)

// do all sorting in the selectors!
// we retrieve the data from the server and just store them as dictionaries
