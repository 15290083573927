/**
 * @file Action creators for chat
 * @author Alwyn Tan
 */

const { createAction } = require('redux-actions')

// socket routes
export const chatInit = createAction('CHAT_INIT')
export const chatSocketConnected = createAction('CHAT_SOCKET_CONNECTED')
export const loadChatRooms = createAction('LOAD_CHAT_ROOMS')
export const joinChatRoom = createAction('JOIN_CHAT_ROOM')
export const setChatRoom = createAction('SET_CHAT_ROOM')
export const sendChatMessage = createAction('SEND_CHAT_MESSAGE')

export const loadChatMessages = createAction('LOAD_CHAT_MESSAGES')
export const receiveLoadedChatMessages = createAction(
  'RECEIVE_LOAD_CHAT_MESSAGES'
)

export const receivePendingChatMessage = createAction(
  'RECEIVE_CHAT_MESSAGE_PENDING'
)
export const resolvePendingChatMessage = createAction(
  'RESOLVE_CHAT_MESSAGE_PENDING'
)

export const receiveChatMessage = createAction('RECEIVE_CHAT_MESSAGE')
export const seenChat = createAction('SEEN_CHAT')
export const updateChatMessage = createAction('UPDATE_CHAT_MESSAGE')

// rest api routes
export const createChatRoomWith = createAction('CREATE_CHAT_ROOM')

// new versions of updating the chats
export const updateChatRoomDetails = createAction('UPDATE_CHAT_ROOM_DETAILS')
export const updateDefaultChatRooms = createAction('UPDATE_DEFAULT_CHAT_ROOMS')
export const updateEventChatRooms = createAction('UPDATE_EVENT_CHAT_ROOMS')

// simple actions to pass to the reducer
export const receiveDefaultChatRoom = createAction('RECEIVE_DEFAULT_CHAT_ROOM')
export const receiveEventChatRoom = createAction('RECEIVE_EVENT_CHAT_ROOM')
